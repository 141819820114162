import { render, staticRenderFns } from "./radiobutton.vue?vue&type=template&id=4bede3f3"
import script from "./radiobutton.vue?vue&type=script&lang=js"
export * from "./radiobutton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.10_lodash@4.17.21_vue-template-c_h2blxftq6bdwsyc6g5ohbop2au/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports