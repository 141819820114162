export default `
Privacy Policy Notice

Effective Date: 09/25/2024

Univents' Privacy Policy describes how Univents collects, uses, and discloses information about you. When we say "Univents," we mean Harmony For Inc. and its affiliates. When we say "organizer" or "user," we mean anyone who uses our platform or attends a related event. This includes an organization or a person who uses the platform on behalf of an organization. This Privacy Policy applies to the processing of information about members and other individuals that Univents collects when you use the Univents Platform (which refers to any website or service offered by Univents) or communicate with Univents. For more information about the choices we provide under this policy, see "Your Choices" below. This Privacy Policy does not apply to information you provide to third parties, such as members, and others with whom you may share information about yourself.

## **1.Information Collection**

In Summary: Univents collects information about you when you:

* Create an account
* Use the Platform
* Attend an event
* Browse the Univents website
* Contact Univents
* View and apply for services offered by Univents

Univents also collects information, such as usage statistics, through the use of cookies, server logs, and similar technologies.

If you choose to use certain features, Univents also collects information through other sources, such as social media services, invitations, and imported contacts.

1.1 Information you provide to Univents Univents collects information you provide directly to us. For example, we collect information you provide when you create an account, select interests or groups, fill out a form, or contact us. The types of information Univents collects include your name, username, password, email address, postal address, telephone number data, photo, and any other information you choose to provide.

1.2 Information Univents Collects Automatically When You Use the Univents Platform

Information Univents collects automatically about you when you use the Univents Platform, including:

* Log Information: Univents collects log information about your use of the Platform, including the type of browser you use, date and time, frequency and duration of visits, pages of the Platform viewed, your IP address, and the page you visited before opening the Univents Platform.
* Device Information: Univents collects information about the computer or mobile device you use to access the Univents Platform, including hardware model, operating system and version, unique device identifiers, and mobile network information.

## **2.Use of Information**

In Short: Univents uses information about you to:

* operate, improve, promote and protect Univents and the Platform
* contact you
* provide information to you
* provide information to and contact third parties, such as universities

2.1 Operating the Univents Platform

Univents may use information about you for a variety of purposes related to the operation of the Univents Platform, including:

Providing, maintaining, and improving the Univents Platform; Developing new features and services; and Managing the performance of the Univents Platform.

Displaying information based on your information.

Customizing the Platform, for example by suggesting content that we believe may be of interest to you (including content that may be subject to special protections under EU law or that relates to sensitive information such as health, political opinions, religion, and sexual identity).

If you connect your account through a third-party social media service, allowing you to share information about your use of the Platform through such service and to provide you with better recommendations.

Analyzing trends, usage, and activity in connection with the Univents Platform.

Detecting, investigating, and preventing fraud, abuse, and other illegal activity, to protect the rights, property, or safety of Univents and others, to enforce compliance with Univents' policies, and to comply with applicable law and government requests.

To analyze and contact users to provide them with information that may be of interest to them

To provide information to third parties such as universities approved by Univents

2.2 Communicating with You

Univents may use information about you for a variety of purposes related to communicating with you, including to:

1. Responding to your comments, questions, and requests, and providing customer service.
2. Communicating with you about products, jobs, services, and events offered by our company and others.
3. Notifying you of communications from other members in accordance with the communication preferences you have indicated in your account.
4. Sending you technical notices, updates, security alerts/support, and administrative messages.

2.3 Advertising and other uses

Univents may use information about you for a variety of other purposes, including to:

1. To provide content, features or sponsorships that match a member's profile or interests;
2. To facilitate promotions;
3. To combine with information Univents collects for the purposes described in this Privacy Policy;
4. To carry out any other purposes Univents describes to users at the time the information is collected.

2.4 Legal basis for processing your data Our legal basis for collecting and using the information described above will depend on the type of information and the specific context in which we collect it.

1. We process information about you to provide the Services in accordance with the Terms of Use, for example to allow you to follow groups or to display your profile to other members and to allow us to send you important service updates.
2. We process information about you when it is in our legitimate interests and does not violate your rights (e.g. direct marketing, fraud prevention, network and information system security, responding to your communications, operating the group network with the network administrators, and improving the Platform).
3. You may provide us with sensitive information about yourself. For example, the mere fact of following a particular organization may suggest information about your health, religion, political views, or sexual identity. Following such organizations and posting information about these topics of interest on the Platform is entirely voluntary and with your explicit consent.
4. Univents may have a legal obligation to collect information about you, or may otherwise need the information to protect your vital interests and those of other users.
5. Univents also processes information to comply with legal requirements or to perform a contract.

## **3.Sharing of Information**

In Short: Univents doesn’t share information about users other than as described in this Privacy Policy.

Univents may disclose information about users:

* To organisers or social media services, in accordance with the choices you make through the platform;
* To Univents' third party service providers who help us operate or improve the Univents Platform;
* To authorities, to comply with legal obligations and to protect and defend our rights and property;
* To affiliates or transferee entities as part of corporate transactions.

Univents may also share de-identified or aggregated information.

3.1 Through the Univents Platform

Univents shares some of the information it collects by displaying the information on the Platform in accordance with your member profile and account settings.

3.2 Compliance with Law and Protection of Univents

Univents may access, preserve, and disclose information about you to third parties if we believe doing so is in accordance with or required by any applicable law, regulation, legal process, or audit. Univents may also disclose information about you if we believe your actions are in violation of Univents' Terms of Use or related guidelines and policies, or if such disclosure is necessary to protect the rights or safety of Univents or others, or to prevent fraud or abuse of Univents or others.

3.3 Social Share and Other Tools

The Platform may provide social share features and other integrated tools that share your actions on our Platform with third-party services (and vice versa). These features allow you to share information with your friends or the public, based on the settings you select on the Services.

3.4 Sharing with Affiliates, Mergers, Sales, and Other Asset TransfersIf Univents becomes involved in a merger, acquisition, financing, reorganization, bankruptcy, or asset sale, information about users may be shared, sold, or transferred as part of that transaction. Univents may also share information about users with current or future parent, subsidiary, or affiliate companies.

## **4.Additional Details**

* With your permission, Univents may allow other companies to use technology to collect information about you to provide analytics services and to serve Univents advertisements. You may opt out of the collection or use of this information.
* We use commercially reasonable efforts to protect your personal information and implement technical and organizational measures consistent with industry best practices. Univents cannot guarantee that these controls provide absolute security.
* This Privacy Policy is updated periodically but does not apply to third parties.

4.1 Analytics and Advertising Services Provided by Others

With your permission, we allow other companies to use cookies, web beacons, device identifiers, and other technologies to collect information about your use of the Platform or other websites or online services.

4.2 Changes to this Policy

Univents may change this Privacy Policy from time to time. When we do, we will notify you by posting an updated version and indicating the update date at the top of this page. If we make material changes to this Policy, we will provide you with additional notice, such as by emailing you or posting a prominent notice on the Platform.

## **5.Contact Information**

If you have any questions, please contact us at privacy@Univents.jp.
The controller of your information is Harmony For Inc.
If you have any questions or complaints regarding this Privacy Policy or how we use your information, please contact us at privacy@Univents.jp.

`
