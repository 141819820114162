<template>
  <VContainer>
    <VSnackbar v-model="snackbar" color="error">
      {{ snackbarText }}
    </VSnackbar>
    <h2>{{ msg["co_hosts"] }}</h2>
    <VDialog v-model="invitationDialog">
      <template v-slot:activator="{ on, attrs }">
        <VBtn v-on="on" v-bind="attrs">
          <VIcon>mdi-plus</VIcon>Invite
        </VBtn>
      </template>
      <VCard>
        <VCardTitle>Invite Co-Host</VCardTitle>
        <VCardText>
          <VTextField label="Email" v-model="inviteTargetEmail" hint="Please Enter Co-Host's Email Address"
            type="email" />
        </VCardText>
        <VCardActions>
          <VBtn @click="invite">Invite</VBtn>
        </VCardActions>
      </VCard>
    </VDialog>

    <VDataTable v-if="event.is_fetched" :items="items" :headers="headers">
      <template v-slot:[`item.status`]="{ item }">
        <VChip :color="item.status_color">
          {{ item.status }}
        </VChip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <VDialog>
          <template v-slot:activator="{ on, attrs }">
            <VBtn v-on="on" v-bind="attrs" icon v-if="item.isDeletable">
              <VIcon>mdi-delete</VIcon>
            </VBtn>
          </template>
          <VCard>
            <VCardTitle>{{ item.organization.organization_name }}</VCardTitle>
            <VCardText>
              <p v-if="organization_id == item.organization_id">
                This is your current organization.<br/>
                You may no longer be able to access this event.
              </p>
              <p>If you want to delete this co-host, please click delete button.</p>
            </VCardText>
            <VCardActions>
              <VBtn @click="deleteItem(item)" color="warning">Delete</VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      </template>
    </VDataTable>
  </VContainer>
</template>

<script>
import Event from '@/models/event'
import Organization from '@/models/organization'
import messages from '@/locales/event_manage.json'

const calcStatus = (organization_event) => {
  // owner / invited / accepted / declined
  // white / yellow / green / red

  if (organization_event.is_owner) return { status: "owner", status_color: "white" }
  if (organization_event.declined_at) return { status: "declined", status_color: "red" }
  if (organization_event.accepted_at) return { status: "accepted", status_color: "green" }
  return { status: "invited", status_color: "yellow" }
}

export default {
  props: [
    "event_id",
    "organization_id",
  ],
  data() {
    return {
      event: new Event(this.event_id),
      inviteTargetEmail: "",
      invitationDialog: false,
      snackbar: false,
      snackbarText: "",
    }
  },
  computed: {
    msg() { return messages[this.$store.state.user.language] },
    headers() {
      return [
        { text: 'name', value: 'organization.organization_name' },
        { text: 'Email', value: 'organization.email' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
    items() {
      if (!this.event.is_fetched) return []
      return this.event.organization_events
        .map(organization_event => {
          const status = calcStatus(organization_event)
          return {
            ...organization_event,
            organization: new Organization(organization_event.organization),
            ...status,
            isDeletable: (status.status === 'invited' || organization_event.organization_id == this.organization_id) && !organization_event.is_owner,
          }
        })
        .toSorted((a, b) => new Date(b.created_at) - new Date(a.created_at))
    },
  },
  methods: {
    invite() {
      this.event
        .inviteCoHost({
          inviteTargetEmail: this.inviteTargetEmail,
          currentOrganizationId: this.organization_id,
        })
        .then(() => {
          this.invitationDialog = false
          this.inviteTargetEmail = ""
          this.event.fetch()
        })
        .catch(() => {
          this.notify("Failed to invite co-host")
        })
    },
    deleteItem(item) {
      this.event
        .deleteCoHost({
          targetOrganizationId: item.organization_id,
          currentOrganizationId: this.organization_id,
        })
        .then(() => {
          if (item.organization_id == this.organization_id) {
            this.$router.push(`/manage/organizations/${this.organization_id}/events`)
          } else {
            this.event.fetch()
          }
        })
        .catch(() => {
          this.notify("Failed to delete co-host")
        })
    },
    notify(text) {
      this.snackbarText = text
      this.snackbar = true
    }
  },
}
</script>

<style></style>
