import axios from '@/store/axios'
import display_lang from '@/models/display_lang';

export default class Organization {
  constructor(arg) {
    this.init()
    if (typeof arg == "string") {
      this.id = arg
      this.fetch()
    }
    if (typeof arg == "object") {
      Object.assign(this, arg)
      this.is_fetched = true
      this.setLangParams(this.current_lang)
      this.set_ids()
    }
  }

  fetch() {
    axios.get(`/api/v1/organizations/${this.id}`).then(resp => {
      Object.assign(this, resp.data)
      this.is_fetched = true
      this.setLangParams()
      this.set_ids()
    })
  }

  set_ids() {
    this.university_ids = this.universities.map(function (i) { return i.id })
    this.prefecture_ids = this.prefectures.map(function (i) { return i.id })
  }

  init() {
    this.icon_url = null
    this.header_url = null
    this.email = null
    this.phone_num = null
    this.is_hf = null
    this.language_organizations = [
      {
        language_id: null,
        name: "",
        discription: "",
        is_main: false,
      }
    ],
    this.universities = []
    this.university_ids = []
    this.prefectures = []
    this.prefecture_ids = []
    // setLangでセットされる
    this.name = null
    this.discription = null
    // 状態管理用
    this.is_fetched = false
    this.current_lang = null
    this.link_instagram = null
    this.link_wechat = null
    this.link_facebook = null
    this.link_line = null
    this.link_x = null
    this.link_official_site = null
  }

  update() {
    this.fetch()
  }

  language_organization(lang_id) {
    return this.language_organizations.find(lang_org => lang_org.language_id == lang_id)
  }

  main_language_organization() {
    return this.language_organizations.find(lang_org => lang_org.is_main)
  }

  get organization_name() {
    return this.main_language_organization()?.name || this.language_organizations[0].name
  }

  second_language_organization() {
    return this.language_organizations.filter(lang_org => {
      return !lang_org.is_main
    })[0]
  }

  language_ids() {
    var lang_array = this.language_organizations.map(function (lang_org) {
      return lang_org.language_id
    })
    return lang_array
  }

  setInitLang(id) {
    this.current_lang = id
  }

  setLangParams(id) {
    var lang_id = display_lang(this.language_organizations)
    var lang_org = this.language_organization(lang_id)
    this.name = lang_org.name
    this.discription = lang_org.discription
    this.current_lang = id
  }

  toPostedOrganization() {
    var postedOrganization = {}
    postedOrganization.id = this.id
    postedOrganization.icon_url = this.icon_url
    postedOrganization.email = this.email
    postedOrganization.phone_num = this.phone_num
    postedOrganization.header_url = this.header_url
    postedOrganization.university_ids = this.university_ids
    postedOrganization.prefecture_ids = this.prefecture_ids
    postedOrganization.language_organizations_attributes = this.language_organizations
    postedOrganization.link_instagram = this.link_instagram
    postedOrganization.link_wechat = this.link_wechat
    postedOrganization.link_facebook = this.link_facebook
    postedOrganization.link_line = this.link_line
    postedOrganization.link_x = this.link_x
    postedOrganization.link_official_site = this.link_official_site
    return postedOrganization
  }
}
