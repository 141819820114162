<template>
  <v-card id="app">

    <v-toolbar flat color="primary" dark>
      <v-toolbar-title class="pa-5">{{ organization.main_language_organization()?.name }}</v-toolbar-title>
    </v-toolbar>


    <v-tabs vertical v-model="activeTab">

      <v-tab v-for="tab in tabs" class="ml-0" :key="tab.id" :to='tabRoutes(tab.id)' style="display: flex; justify-content: flex-start; gap: 12px;">
        <v-icon class="mx-3">{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>

      <v-tab-item v-for="tab in tabs" :key="tab.id" :value="tabRoutes(tab.id)" :transition="false"
        :reverse-transition="false">
        <v-card flat>
          <v-card-text>
            <router-view v-if="activeTab == tabRoutes(tab.id)" :organization="organization"></router-view>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>


  </v-card>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import Organization from '@/models/organization'
import messages from '@/locales/manage_organization.json'

export default {
  props: [
    "organization_id"
  ],
  data: function () {
    return {
      organization: new Organization(this.organization_id),
      root: `/manage/organizations/${this.organization_id}`,
      activeTab: window.location.pathname,
      hasCoHostsInvitation: false,
    }
  },
  computed: {
    user() { return this.$store.state.user },
    organizationName() { return this.organization.language_organizations[0].name },
    msg() { return messages[this.$store.state.user.language] },
    tabRoutes(id) {
      return function (id) {
        return this.root + this.tabs[id - 1].route
      }
    },
    tabs() {
      const tabs = [
        { id: 1, icon: 'mdi-calendar-text', name: this.msg.event, route: '/events' },
        { id: 2, icon: 'mdi-settings', name: this.msg.setting, route: '/edit' },
        { id: 3, icon: 'mdi-account', name: this.msg.member, route: '/members' },
        { id: 4, icon: 'mdi-account-star', name: this.msg.followers, route: '/followers' },
      ]

      if (this.hasCoHostsInvitation) {
        return [
          ...tabs,
          { id: 5, icon: 'handshake', name: this.msg.co_hosts, route: '/co-hosts' },
        ]
      } else {
        return tabs
      }
    },
  },
  created() {
    this.$store.dispatch('user/update')
  },
  methods: {
  },
  mounted() {
    axios.get(`/api/v1/organizations/${this.organization_id}`).then(resp => {
      this.organization.language_organizations = resp.data.language_organizations
    })
    axios.get(`/api/v1/organizations/${this.organization_id}/co-host-invitations`).then(resp => {
      this.hasCoHostsInvitation = resp.data.length > 0
    })
  }
}

</script>

<style media="screen">
.org-menu {
  background-color: green;
}
</style>
