<template>
  <div id="app">
    <h3>{{ msg.co_hosts }}</h3>
    <h4>Invitations</h4>
    <VDataTable :headers="headers" :items="items">
      <template v-slot:[`item.actions`]="{ item }">
        <div style="display: flex; gap: 12px;">

          <VDialog v-model="acceptDialog">
            <template v-slot:activator="{ on, attrs }">
              <VBtn v-on="on" v-bind="attrs" color="success">
                <VIcon small>mdi-thumb-up</VIcon>Accept
              </VBtn>
            </template>

            <VCard>
              <VCardTitle>Accept</VCardTitle>
              <VCardText>
                Are you sure you want to accept the invitation to co-host this event?
              </VCardText>
              <VCardActions>
                <VBtn @click="accept(item)">Accept</VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
          <VDialog v-model="declineDialog">
            <template v-slot:activator="{ on, attrs }">
              <VBtn v-on="on" v-bind="attrs" color="error">
                <VIcon small>mdi-cancel</VIcon>Decline
              </VBtn>
            </template>

            <VCard>
              <VCardTitle>Decline</VCardTitle>
              <VCardText>
                Are you sure you want to decline the invitation to co-host this event?
              </VCardText>
              <VCardActions>
                <VBtn @click="decline(item)">Decline</VBtn>
              </VCardActions>
            </VCard>
          </VDialog>
        </div>
      </template>
    </VDataTable>
    <VSnackbar v-model="snackbar">
      {{ snackbarText }}
    </VSnackbar>
  </div>
</template>

<script>
import messages from '@/locales/co_hosts.json'
import axios from '@/store/axios'
import Event from '@/models/event'
import Organization from '@/models/organization'

export default {
  props: ['organization_id'],
  data() {
    return {
      acceptDialog: false,
      declineDialog: false,
      snackbar: false,
      snackbarText: "",
      organization_events: [],
    }
  },
  computed: {
    msg() { return messages[this.$store.state.user.language] },
    headers() {
      return [
        { text: 'Event Title', value: 'event.title' },
        { text: 'Invited by', value: 'invitedBy.name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
    items() {
      return this.organization_events.map(item => {
        const event = new Event(item.event_id.toString())
        const invitedBy = new Organization(item.invited_by_id.toString())
        return {
          ...item,
          event,
          invitedBy,
        }
      })
    },
  },
  methods: {
    accept(item) {
      axios
        .put(`/api/v1/organizations/${this.organization_id}/co-host-invitations/${item.id}/accept`)
        .then(resp => {
          this.snackbar = true
          this.snackbarText = "Accepted"
          this.$router.push(`/manage/organizations/${this.organization_id}/events/${item.event_id}`)
        })
        .catch(err => {
          this.snackbar = true
          this.snackbarText = "An error occurred. Please try again."
        })
        .finally(() => {
          this.acceptDialog = false
        })
    },
    decline(item) {
      axios
        .put(`/api/v1/organizations/${this.organization_id}/co-host-invitations/${item.id}/decline`)
        .then(resp => {
          this.snackbar = true
          this.snackbarText = "Declined"
        })
        .catch(err => {
          this.snackbar = true
          this.snackbarText = "An error occurred. Please try again."
        })
        .finally(() => {
          this.declineDialog = false
          this.fetchInvitations()
        })
    },
    fetchInvitations() {
      axios.get(`/api/v1/organizations/${this.organization_id}/co-host-invitations`).then(resp => {
        this.$set(this, 'organization_events', resp.data)
      })
    },
  },
  created() {
    this.fetchInvitations()
  },
}
</script>

<style></style>
