<template>
  <div
    class="pa-3 privacy-policy-body"
    v-html="content"
  />
</template>

<script type="text/javascript">
import { marked } from 'marked'

import japanese from './japanese.js'
import english from './english.js'

export default {
  computed: {
    content(){
      return marked.parse(this.$store.state.user.language == 'ja' ? japanese : english)
    },
  }
}
</script>

<style>
  .privacy-policy-body {
    font-size: 16px;
  }
  .privacy-policy-body h3 {
    font-size: 1.5em;
    margin-bottom: 1rem;
  }
  .privacy-policy-body ul {
    padding: 1rem 0;
  }
</style>
