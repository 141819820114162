<template>
  <div id="app">
    <v-toolbar color="primary" dark extended>
      <v-toolbar-title class="pa-5 clickable" @click="$router.push(`/manage/organizations/${organization_id}`)">
        {{ organization.organization_name }}
      </v-toolbar-title>

      <template #extension>
        <v-toolbar-items class="mt-3">
          <h4><span @click="$router.push(`/manage/organizations/${organization_id}`)" class="clickable">{{
            msg.organization_page }}</span> > {{ event.title }}</h4>
          <span @click="$router.push(`/events/${event_id}`)" style="cursor: pointer"
            class="ml-2"><v-icon>remove_red_eye</v-icon></span>
        </v-toolbar-items>
      </template>
    </v-toolbar>

    <v-tabs vertical v-model="activeTab">

      <v-tab v-for="tab in tabs" class="ml-0" :key="tab.id" :to='tabRoutes(tab.id)'
        style="display: flex; justify-content: flex-start; gap: 12px;">
        <v-icon class="mx-3">{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>

      <v-tab-item v-for="tab in tabs" :key="tab.id" :value="tabRoutes(tab.id)" :transition="false"
        :reverse-transition="false">
        <router-view v-if="activeTab == tabRoutes(tab.id)" :organization_id="organization_id" :event_id="event_id"
          :event="event"></router-view>
      </v-tab-item>

    </v-tabs>

  </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import Event from '@/models/event'
import Organization from '@/models/organization'
import messages from '@/locales/event_manage.json'

export default {
  props: [
    "organization_id",
    "event_id"
  ],
  data: function () {
    return {
      event: new Event(this.event_id),
      organization: new Organization(this.organization_id),
      root: `/manage/organizations/${this.organization_id}/events/${this.event_id}/`,
      activeTab: window.location.pathname,
    }
  },
  computed: {
    user() { return this.$store.state.user },
    eventName() { return this.organization.language_organizations[0].name },
    tabRoutes(id) {
      return function (id) {
        return this.root + this.tabs[id - 1].route
      }
    },
    msg() { return messages[this.$store.state.user.language] },
    tabs() {
      return [
        { id: 1, icon: 'edit', name: this.msg.edit, route: 'edit' },
        { id: 2, icon: 'record_voice_over', name: this.msg.annouce, route: 'announce' },
        { id: 3, icon: 'email', name: this.msg.message, route: 'remind' },
        { id: 4, icon: 'people', name: this.msg.participant, route: 'list' },
        // { id: 5, icon: 'assignment', name: this.msg.slip, route: 'slip' },
        { id: 5, icon: 'handshake', name: this.msg.co_hosts, route: 'co-hosts' },
      ]
    }
  },
  created() {
    // this.$store.dispatch('auth/destroy')
    this.$store.dispatch('user/update')
  },
  created() {
    // eventページから飛んだ時に，owner_organizationのevent管理ページに飛んでしまう
    // 飛んだ先のowner_organizationが自分の所属していないorganizationの場合，そのorganizationのevent管理ページに飛ぶ
    axios.get(`/api/v1/events/${this.event_id}`).then(resp => {
      const organizationOrganizerIds = new Map(
        resp.data.event.organization_events
          .map(oe => oe.organization)
          .map(org => [org.id.toString(), org.organizer_ids])
      );

      if (!organizationOrganizerIds.get(this.organization_id).includes(this.user.id)) {
        const organizationId = Array.from(organizationOrganizerIds).find(([_, organizerIds]) => organizerIds.includes(this.user.id))[0];
        if (organizationId === undefined) {
          this.$router.push(`/`);
        }
        this.$router.push(`/manage/organizations/${organizationId}/events/${this.event_id}`)
      }
    })
  },
  watch: {
    'organization_id'() {
      window.location.reload()
    }
  }
}

</script>

<style media="screen">
.evt-menu {
  background-color: red;
}

.v-tab {
  justify-content: left;
}

.clickable {
  cursor: pointer;
}
</style>
