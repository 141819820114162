<template>
  <v-form ref="form" lazy-validation>
    <v-text-field v-model="organization.language_organization(organization_lang_id).name" :label="msg.name_organizer" class="required" :rules="nameRules"></v-text-field>
    <v-text-field v-model="organization.email" :label="msg.mail_organizer" class="required" :rules="emailRules"></v-text-field>
    <v-text-field v-model="organization.phone_num" :label="msg.phone_number_organizer"></v-text-field>
    <v-textarea :hint="msg.description_organizer_hint" persistent-hint v-model="organization.language_organization(organization_lang_id).discription" :label="msg.description_organizer"></v-textarea>
    <v-select :label="msg.prefecture_organizer" :items="master.prefectures" item-text="name" item-value="id" multiple v-model="organization.prefecture_ids"></v-select>
    <v-select :label="msg.affiliated_uni_organizer" :items="target_university_options" item-text="name" item-value="id" multiple v-model="organization.university_ids" no-data-text="You can choose universities only in main prefectures">
    </v-select>
    <v-text-field v-model="organization.link_instagram" :label="msg.instagram_organizer"></v-text-field>
    <v-text-field v-model="organization.link_wechat" :label="msg.wechat_organizer"></v-text-field>
    <v-text-field v-model="organization.link_facebook" :label="msg.facebook_organizer"></v-text-field>
    <v-text-field v-model="organization.link_line" :label="msg.line_organizer"></v-text-field>
    <v-text-field v-model="organization.link_x" :label="msg.x_organizer"></v-text-field>
    <v-text-field v-model="organization.link_official_site" :label="msg.website_organizer"></v-text-field>
  </v-form>
</template>


<script>
import messages from '@/locales/create_organization.json'

export default {
  props:[
    'organization_lang_id',
    'organization',
    'master'
  ],
  data(){
    return {
      nameRules: [ v => ( !!v ) || "Name is required." ],
      emailRules: [ v => ( !!v ) || "Email can't be blank", v => /.+@.+\..+/.test(v) || "E-mail must be valid" ],
    }
  },
  computed: {
    msg() { return messages[this.$store.state.user.language] },
    target_university_options() {
      return this.master.universities.filter(univ => {
        return this.organization.prefecture_ids.includes(univ.prefecture_id) || this.organization.university_ids.includes(univ.id)
      })
    }
  }
}
</script>
