<template>
  <div
    class="pa-3 terms-of-service-body"
    v-html="content"
  />
</template>

<script type="text/javascript">
import { marked } from 'marked'

import japanese from './japanese.js'
import english from './english.js'

export default {
  computed: {
    content(){
      return marked.parse(this.$store.state.user.language == 'ja' ? japanese : english)
    },
  }
}
</script>

<style>
  .terms-of-service-body {
    font-size: 16px;
    height: 150px;
    overflow-y: scroll;
    resize: vertical;
    min-height: 100px;
    border: solid 1px #d4d4d4;
  }
  .terms-of-service-body h3 {
    font-size: 1.5em;
    margin-bottom: 1rem;
  }
  .terms-of-service-body ul {
    padding: 1rem 0;
  }
</style>
