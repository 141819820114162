export default `
## サービス利用規約の概要

以下は、Univentsの「プラットフォーム」に関するサービス利用規約の概要です。「プラットフォーム」とは、 Univentsが提供するウェブサイト、アプリケーション、またはサービスを指します。

### **ユーザーとUniventsとの関係**

* Univentsのプラットフォームを使用することにより、ユーザーはUniventsのサービス利用規約に同意したものとみなされます。このサービス利用規約は、ユーザーとUniventsとの間の法的拘束力のある契約となります。
* ユーザーが規則を破った場合、Univentsはユーザーのアカウントの停止または解除を行う場合があります。

### **Univentsのメンバー**

* メンバーは誰でも自分の団体を作成することもできます。
* Univentsのプラットフォームを使用することで、ユーザーは実世界に存在する人々と出会い、実際にさまざまな活動を行うことができます。しかしこれは、時に予期せぬ状況をもたらす可能性があります。Univentsは実世界で起きることを管理することはできず、それらについて責任を負いません。他者と交流する際には、ユーザーの方自身の常識のもとで正しい判断を行っていただくようお願いいたします。

### **ユーザーコンテンツと他者のコンテンツ**

* ユーザーは、ユーザー「コンテンツ」（Univentsプラットフォームに投稿されたあらゆる情報、素材、またはその他の内容を指します）について責任を負うものとします。
* Univentsは、Univentsプラットフォームを使用してメンバーが投稿するコンテンツや送信するコミュニケーションについて一切の責任を負いません。通常、Univentsは投稿前にコンテンツをレビューすることはありません。Univentsサービス利用規約に違反するコンテンツを見かけた場合、ユーザーはUniventsに不適切なコンテンツを報告することができます。

### **Univentsプラットフォーム**

* Univentsは、Univentsプラットフォームを常に利用可能な状態に保ち、機能させるために尽力していますが、このような状態を常時保証することはできません。時折、計画通りに作業が進行しない事態が生じる可能性がございます。ご不便をおかけしますことをあらかじめお詫び申し上げます。
* Univentsは、Univentsプラットフォームの継続的な改善に取り組んでいます。このためUniventsは、Univentsプラットフォームの一部を修正または停止することがあります。
* Univentsのプラットフォームを使用することで、ユーザーはUniventsのサービス利用規約に定められた責任制限と免除について、同意することになります。また、ユーザーは、Univentsとの間に紛争が発生した場合にそれをUniventsのサービス利用規約に規定されている方法で解決することに同意するものとします。さらにユーザーは、ユーザーの拠点が日本でない限り、強制仲裁に同意し、裁判所において判事または陪審から救済を求める権利を放棄することに同意します。申し立ては個別にのみ起こすことができ、集団訴訟の一部として提起することはできません。
* 一部のユーザーは、適用される権利および義務が異なるか、または追加される国を拠点としている可能性があります。このサービス利用規約ではこうした地域の差異も指摘しています。

## サービス利用規約

最終更新日：2024/9/25

Univentsのサービス利用規約には、ユーザーの法的権利についての重要な情報が含まれています。灰色の枠内には各条項の概要とヒントを記載していますので、必要に応じてご参照ください。これらの内容は正式な条項の一部ではないため、ご利用時には各条項の文言を精読いただくようお願いいたします。

### **1\. 本契約**

**要約：**ユーザーは、Univentsのプラットフォームを使用する際に基本的な規則に従うことに同意します。これらの規則は、下記で説明される関連ポリシーとガイドラインも含めて、本サービス利用規約に説明されています。Univents側にてこれらの規則を変更する場合があります。

**1.1 契約。** Univentsは、ユーザーがイベントの開催を支援するサービスです。「Univents」、「弊社」には、Harmony For Inc.が含まれます。また「ユーザー」は、Univentsプラットフォームを使用するあらゆる個人、および、組織を代表してUniventsプラットフォームを使用するあらゆる組織や個人を指します。

**1.2 本契約の改定。**Univentsは、本契約を適宜改定することがあります。改定した場合、最新版を発行し、本ページ上部記載の日付を変更することで、ユーザーに通知するものとします。Univentsが本契約に対し著しい変更を行った場合、Eメール送信やUniventsプラットフォーム上に目立つ通知を表示するなど、他の方法でも通知をお送りします。 改定が有効になった後に引き続きプラットフォームを使用した場合、ユーザーは改定版の本契約に同意したこととなります。改定版の本契約に同意することを希望しない場合は、ユーザーはアカウントを閉鎖することができます。

### **2\. ユーザーアカウントとメンバーシップ**

**2.1 ユーザーアカウントの変更、停止、または解除。** Univentsは、ユーザーのアカウントまたはUniventsプラットフォームへのユーザーのアクセスを変更、停止、または解除する場合があります。これが行われるのは、本契約（本契約の一部であるあらゆるポリシーまたはガイドラインを含みます）にユーザーが違反した場合や、そうすることがUniventsコミュニティの最善の利益であるかUniventsのブランドまたはプラットフォームを保護するために必要であるとUniventsが独自に判断した場合です。変更、停止、または解除理由はユーザーに通知するものとします。Univentsはまた、長期間にわたり使用されていないメンバーアカウントを削除する場合があります。変更、停止または解除が誤って行われたと思われる場合は、policy@Univents.jpまでご連絡ください。

Univentsのプラットフォームの特定の部分を管轄または管理する能力を有するメンバーは、その方自身の判断により、当該部分へのユーザーのアクセスを変更、停止、または解除する能力も有しています。

**2.2 アカウント情報とセキュリティ。** ユーザーは登録時に、Eメールアドレスとパスワードを含む基本情報をUniventsに提供することになります。Eメールアドレスなどアカウントに関する情報は、常に最新かつ正確なものであることをご確認ください。また、ユーザーは自身のパスワードのセキュリティと機密性を維持することに同意するものとします（同意していただけない場合、Univentsにてユーザーアカウントを無効化せざるを得ない場合もあります）。パスワードは強力かつ一意の単語を使用し、他のアカウントやオンラインサービスで同じパスワードを使用しないなど、自分のアカウントが許可なくアクセスされることを防ぐために、パスワードを健全に管理するよう習慣づけることを強くお勧めします。ユーザーのアカウントの認証情報を他者と共有するなど、パスワードのセキュリティと機密性を維持することをユーザーが怠ったことから生じる事態については、当該ユーザーが全責任を負うものとします。自身のパスワードを他者が使用している場合、または自身のアカウントに許可なくアクセスしているという場合は、abuse@Univents.jpまでメールでご連絡ください。

**2.3 Univentsプラットフォームおよびサービスに対するライセンス**。本契約を遵守することを条件に、Univentsは、Univentsがユーザーに提供するサービスおよび機能にアクセスし、これを使用するために、プラットフォームを使用する限定的、非排他的、取り消し可能、二次許諾不能、譲渡不能な権利をユーザーに付与します。

### **3\. ユーザーコンテンツとプライバシー**

**要約：** ユーザーは、Univentsプラットフォームに自分が投稿する、またはUniventsに送信するコンテンツについて責任を負うものとします。ユーザーは、UniventsとUniventsプラットフォームの運営、改善、推進、保護のためにこれらのコンテンツを使用するための許諾をUniventsに付与します。Univentsのプライバシーポリシーでは、ユーザーが自身について提供する情報およびユーザーに関係する可能性のある情報をUniventsが収集し使用する方法と、Univentsがそれらの情報を共有できる特定の方法についてご説明しています。

**3.1 ユーザーのコンテンツ。** ユーザーは、プラットフォームに自身が投稿したコンテンツ、またはUniventsに提供したコンテンツについて責任を負うものとします。ここでいう「コンテンツ」とは、ユーザーが自分で投稿した、またはUniventsに提出した情報、資料、その他のコンテンツを指します。ユーザーコンテンツの例：

* UniventsグループやUniventsイベントの情報、コメント、写真など、オーガナイザーおよびメンバーがプラットフォームに投稿する素材。
* Univents宛に送るすべてのフィードバックやヒント、その他のメッセージ。

ユーザーは自身のコンテンツに対して責任を持ち、特に次の点に同意するものとします。

* ユーザーは、プラットフォームおよびUniventsに対して、コンテンツを提供するために必要な許可、権利、およびライセンスをすべて有しています（著作権、商標、契約上の権利、プライバシー権、またはパブリシティ権に基づくものを含みます）。
* ユーザーのコンテンツには、他人に帰する個人情報、機密情報、極秘情報は含まれません。
* ユーザーのコンテンツが、その他個人または法人の権利を侵していない。

**3.2 プライバシー。** Univentsは、Univentsプラットフォームを通じて、登録情報などのユーザーについての情報を収集します。Univentsがこれらの情報を収集、使用、開示する方法の詳細については、プライバシーポリシーをご参照ください。これらのポリシーは、Univentsのプラットフォームの他のメンバーなど、第三者にユーザーが提供する情報の使用は対象としていません。

### **4\. Univentsプラットフォームの使用**

**要約:** Univentsは、ユーザーがUniventsプラットフォームを使用する際にUniventsのポリシーとガイドラインを遵守するようお願いしています。Univentsは、Univentsプラットフォームに第三者が投稿したコンテンツについては責任を負いません。

**4.1 弊社のポリシー、ガイドライン、および適用法令。**弊社プラットフォームの利用には[利用およびコンテンツポリシー](https://help.meetup.com/hc/en-us/articles/360002897532)、[グループとイベントに関するポリシー](https://help.meetup.com/hc/en-us/articles/360002897712)、[オーガナイザーとリーダーシップの基準](https://help.meetup.com/hc/en-us/articles/360002913291)、[メンバー制限規則](https://help.meetup.com/hc/en-us/articles/360002913271)、[支払いポリシー](https://www.meetup.com/paypolicies/)、[商標使用ガイドライン](https://help.meetup.com/hc/articles/360001655932-Meetup-Trademark-Guidelines)、およびAPIライセンス規約が適用されます。お客様はこれらを含む適用されるすべての法律、規則、および条例を遵守し、第三者の権利を妨害、または侵害しないことに同意するものとします。

また、プラットフォームの一部のエリアまたは機能へのアクセスおよび使用には、追加の規約、ポリシー、規格、またはガイドライン（以下、「追加規約」）が適用されるものとします。そのようなプラットフォームの一部や機能にアクセスする前に、追加規約に同意する必要があります。利用規約と追加規約の間に矛盾がある場合は、追加規約に特段の規定がない限り、プラットフォームの一部または機能へのアクセスや使用に関しては、追加規約が優先されます。

Univentsプラットフォームが特定の機能やサービスをメンバーに提供するためにサードパーティのサービスを使用する場合、メンバーはこれらの機能やサービスに適用されるサービス利用規約に従うことを求められる場合があります。たとえば、Univentsプラットフォームの一部のエリアでは、Google マップやGoogle Earthが提供するマッピングサービスが動作します。ユーザーはUniventsプラットフォームでこれらを使用することにより、Google マップ / Google Earth 追加利用規約に同意したものとされます。

ユーザーが規約を遵守しなかった場合、Univentsは、その独自の裁量において、アカウントまたはプラットフォームへのアクセスを変更、停止または解除する場合があります。かかる場合、Univentsは、変更、停止または解除を行った理由をユーザーに提供します。変更、停止または解除が誤って行われたと思われる場合は、policy@Univents.jpまでご連絡ください。

**4.2 他者のコンテンツ。** Univentsが、他のメンバーのコンテンツを管理することはありません。Univentsのプラットフォーム上に不適切なコンテンツが存在することが発覚した場合、Univentsはそれを調査し適切な措置を講じる権利を留保しますが、他のメンバーのコンテンツについては監視義務や責任を一切負いません。

**4.3 他者との交流。**Univentsは、Univentsプラットフォームを通じて行われるオフラインの取り決めの当事者ではありません。Univentsはメンバーの身元確認を実施せず、それを義務付けることもせず、またメンバーの声明の真実性または正確性を検証しようと試みることもありません。Univentsは、いかなるメンバーの行動やコンテンツ、またはメンバーとユーザーとの間の交流について、一切の表明または保証を行いません。

**ヒント：**Univentsのプラットフォームを使用して他のメンバーとやりとりを行う際は、プラットフォーム上およびUniventsイベントのいずれにおいても、常識と適切な判断をもってご利用いただくようお願いいたします。他のメンバーに関する懸念がある場合は、abuse@Univents.comまでご連絡ください。

**4.4 プラットフォームにおける禁止行為。** Univentsプラットフォームは専有情報および機密情報を含んでおり、知的財産関連およびその他の法律により保護されています。本契約を通じてUniventsが明示的に許可していない限り、ユーザーは、直接的か間接的かを問わず、次のことを行わないことに同意するものとします。(a) プラットフォームまたはその一部を使用、主催、保管、複製、変更、公開、公に表示、公に実演、配布するか、またはその二次的著作物を作成すること、(b) プラットフォームの所有権通知を削除または変更すること。(c) プラットフォームのソースコードまたは構造、順序、機構をリバースエンジニアリング、逆アセンブル、逆コンパイルするか、または発見を試みること、並びに、(d) 本契約で意図されていない商業目的でプラットフォームを賃借、リース、再販、配布、または使用すること。また、ユーザーは、次のことを収集するためにプラットフォームを使用しないことにも同意するものとします。(i) グループもしくはイベントの管理または参加に必要な場合を除き、他者の個人データ、または (ii) 適用法で定められるかもしくは取り扱われている、機密性の高い個人データ（健康情報、ソーシャルセキュリティ番号およびその他の国民識別番号を含むが、これらはほんの一例に過ぎない）。また、ユーザーは、現地の法域の輸出規制法を順守することに同意するものとします。

**4.5 プラットフォームの安全性とセキュリティ。**ユーザーは、直接的か間接的かを問わず、次のことを行わないことに同意するものとします。(a) 自動化されたシステムまたはソフトウェアの使用、およびサードパーティによる操作やそれ以外の方法（以下、「スクリーンスクレイピング」、「データスクレイピング」または「ウェブスクレイピング」）のいずれかを問わず、本サービス利用規約で許可されていない商業目的でプラットフォームからデータを取り出すこと、(b) プラットフォームまたはそのシステムに干渉するかこれを中断する（もしくはそのような行為を意図する）、またはこれらに過度の負担をかける一切の活動に関与すること。

ユーザーは、プラットフォームから収集された個人データを、適用法、規則、および規制に従い、かつ、UniventsグループおよびUniventsイベントの管理および参加のみを目的として使用、保持、およびその他処理することに同意するものとします。制限なく、ユーザーは、適用法の定めに従って、個人データの処理に関する通知を個人に提供すること、必要な同意を得ること、および、個人からのリクエストに応答することに同意するものとします。また、ユーザーは、かかるデータを不正なアクセスまたは処理から保護することに同意するものとします。ユーザーはイベントの管理にやむなく必要ではなくなった場合、またはUniventsから要求があった場合は、速やかにかかる個人データを削除しなければならず、またその削除を速やかに証明するものとします。

**4.6 プラットフォームの変更。** Univentsは、Univentsプラットフォームの継続的な改善に尽力しています。そのため、Univentsは、ユーザーまたは第三者に通知するかを問わず、そしてユーザーまたは第三者に対して責任を負うことなく、Univentsプラットフォームの一部または全部を変更または中止する場合があります。

**4.7 サードパーティサイトおよびサービス。** Univentsプラットフォームはサードパーティサイトへのリンクを含んでおり、外部業者のコンテンツおよび製品をユーザーに提供しうる様々なサードパーティサービス、アプリケーション、サイトと統合しています。Univentsではこれらのサードパーティを管理しておらず、これらのサイト、サービス、内容、また製品について責任を負いません。当該サードパーティは独自の取引条件とポリシーを備えている場合があり、ユーザーがこれらのサイト、サービス、コンテンツ、または製品を使用した場合、その会社の定める取引条件とポリシーが適用されます。ユーザーは、単にUniventsプラットフォームにアクセスしたのみでは、サードパーティの知的財産を使用する許可は付与されませんのでご注意ください。

### **5\. 紛争解決**

**要約：**万が一お客様とUniventsとの間で紛争が生じた場合、お客様はまず弊社と直接協議し解決を試みることに同意するものとします。協議を経ても解決に至らない場合は、限られた例外を除き、法廷への申し立てではなく中立の仲裁人に紛争の仲裁依頼を出す必要があります。 なお、依頼は集団訴訟としてではなく、個別案件としてのみ提起することができます。日本に居住のメンバーの方は、適用法で規定されている通り、追加または異なる権利を保有している場合があります。Univentsはユーザーと他のメンバーとの紛争が生じた際に解決に向けて仲立ちを支援することがありますが、これらの紛争に関与する義務は負いません。

**6\. 知的財産**

**要約：** Univentsの商標を使用する場合、必ずUniventsの商標使用ガイドラインに従い、他人の知的財産を侵害しないようご注意ください。

**6.1 Univentsの知的財産。** Univentsの商標、ロゴ、サービス マーク、サービス名はUniventsの知的財産です。プラットフォーム上の素材を含め、Univentsのプラットフォームは、Univentsとそのライセンサーの知的財産です。ユーザーは、Univentsからの書面による事前の同意なく、Univentsの知的財産を使用しないことに同意します。

**6.2 他人の知的財産。** Univentsは他者の知的財産を尊重しており、Univentsのメンバーにも同様の認識を持っていただくことを期待しています。Univentsは、適切な状況、かつUniventsの判断において、他人の知的財産権を侵害している素材を削除、またはその素材へのアクセスを停止する場合があります。また権利侵害の常習者であるとUniventsが判断したユーザーについては、Univentsプラットフォームへのアクセスを制限、または解除する場合もあります。

### **7\. 一般条項**

**要約：** 本項には、Univentsが様々な目的で追加した規約が記載されています。引き続き、本項をよくお読みください。

**7.1 翻訳。** 本契約の原本は日本語で作成され、その後他の言語に翻訳されています。日本語版と翻訳版との間に矛盾がある場合、日本語版が優先するものとします。

**7.2 譲渡。** ユーザーは、Univentsの書面による事前の同意なく、本契約を譲渡、移転、または二次許諾することはできません。対してUniventsは、Univentsの親会社やすべての関係会社、または子会社に対して、あるいは合併、買収、会社再編、Univentsの資産のすべてまたは実質的にすべての売却などの取引との関連において、本契約を譲渡または移転することができます。

**7.3 権利放棄の不存在。**本契約のいずれかの権利または条項をいずれかの当事者が行使または執行しなかった場合でも、その権利または条項が放棄されたことにはならず、今後または類似の違反について対処する権利が放棄されたことにもなりません。

**7.4 謝辞。** Univentsのサービス利用規約をお読みいただき、誠にありがとうございました。

**7.5 契約の終了。** UniventsがユーザーのアカウントまたはUniventsプラットフォームへのアクセスを解除した場合、解除対象となったメンバーアカウントについて本契約を終了するものとします。ただし、第11.13条に定める規定は、契約終了後も存続するものとします。

**7.6 存続。** 本契約の第3.2条（プライバシー）、第5条（紛争解決）、第7条（一般条項）、およびこれらの定めを実施するために必要なその他の定めは、本契約の解約や満了後も存続するものとします。

**7.7 違反。** メンバーまたは第三者による本契約の違反があった場合には、abuse@Univents.jpまでEメールで報告してください。

`
