import { render, staticRenderFns } from "./terms_of_service.vue?vue&type=template&id=40221916"
import script from "./terms_of_service.vue?vue&type=script&lang=js"
export * from "./terms_of_service.vue?vue&type=script&lang=js"
import style0 from "./terms_of_service.vue?vue&type=style&index=0&id=40221916&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.95.0__ejs@3.1.10_lodash@4.17.21_vue-template-c_h2blxftq6bdwsyc6g5ohbop2au/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports