export default `
# **プライバシーポリシーに関する通知**

*発効日：* 2024/09/25

Univentsの「プライバシーポリシー」は、ユーザーについての情報を、どのようにUniventsが収集、使用、開示するかを説明するものです。「Univents」、「弊社」という言葉には、Harmony For Inc.とその関連会社が含まれます。弊社の使用する「オーガナイザー」、「ユーザー」という言葉は、弊社のプラットフォームを使用する、または関連するイベントに参加する人のことを意味します。これには、団体または団体を代表してプラットフォームを使用する人物が含まれます。本プライバシーポリシーは、ユーザーがUniventsの「プラットフォーム」（Univentsが提供するウェブサイト、またはサービスの全てを指します）を使用する際、またはUniventsとコミュニケーションをとる際に、Univentsが収集するメンバーおよびその他の個人に関する情報の処理に適用されます。本ポリシーに基づき弊社が提供する選択肢に関する詳細は、下記の「ユーザーの選択肢」をご覧ください。本プライバシーポリシーは、メンバー等の第三者、およびユーザーが自身に関する情報を共有できる他者に提供する情報には適用されません。

## **1.情報の収集**

要約： Univentsは、ユーザーが次の行為を行う際に、ユーザーについての情報を収集します：

* アカウントの作成
* プラットフォームの使用
* イベントの参加
* Univentsウェブサイトの閲覧
* Univentsへの連絡
* Univentsが提供するサービス情報の閲覧、応募

Univentsはまた、利用統計などの情報を、クッキー、サーバーログ、およびそれに類する技術を使用することで収集します。

ユーザーが特定の機能を使用することを選択した場合、Univentsは、ソーシャルメディアサービス、招待、インポートされた連絡先などのその他の情報源を通じても情報を収集します。

1.1 ユーザーがUniventsに提供する情報

Univentsはユーザーが弊社に直接提供する情報を収集します。例えば、アカウントを作成するとき、関心またはグループを選択するとき、フォームを入力するとき、または弊社に連絡するときに提供する情報を収集します。Univentsが収集する情報の種類には、氏名、ユーザー名、パスワード、メールアドレス、住所、電話番号に関するデータ、写真、およびユーザーが提供することを選択したその他の情報が含まれます。

1.2 ユーザーのUniventsプラットフォーム使用時にUniventsが自動的に収集する情報

ユーザーのUniventsプラットフォーム使用時に、Univentsが自動的に収集するユーザーについての情報には、下記が含まれます：

* ログ情報：Univentsは、ユーザーのプラットフォーム使用に関するログ情報を収集します。これには、ユーザーが使用したブラウザの種類、日時、アクセスした頻度および滞在時間、閲覧したプラットフォームのぺージ、IPアドレス、Univentsプラットフォームを開く前にユーザーが訪問していたぺージが含まれます。
* デバイス情報：Univentsは、Univentsプラットフォームにアクセスする際にユーザーが使用するコンピューターまたはモバイルデバイスについての情報を収集します。これには、ハードウェアの型番、OSとバージョン、固有デバイス識別子、およびモバイルネットワーク情報が含まれます。

## **2.情報の使用**

要約： Univentsは、下記を目的としてユーザーについての情報を使用します：

* Univentsとプラットフォームを運営、改善、推進、保護
* ユーザーへの連絡
* ユーザーへの情報提供
* 大学等第三者への情報提供、連絡

2.1 Univentsプラットフォームの運営

Univentsは、Univentsプラットフォームの運営に関連する様々な目的のために、ユーザーについての情報を使用する場合があります。この目的には、下記のものが含まれます：

1. Univentsプラットフォームを提供、維持、改善すること新規機能およびサービスを開発すること、及びUniventsプラットフォームの性能を管理すること。
2. ユーザーの情報に基づき、情報を表示すること。
3. プラットフォームをカスタマイズすること。例えば、弊社がユーザーの関心と一致すると考えるコンテンツを提案するなど（EU法に基づき特別に保護されるコンテンツや健康、政治的見解、宗教、および性的アイデンティティなどの機密情報に関連するコンテンツも含まれます）。
4. 第三者のソーシャルメディアサービスを通じてアカウントに接続する場合に、そのようなサービスを通じたプラットフォームの使用に関する情報をユーザーが共有できるようにすること、およびユーザーにより良い提案を提供すること。
5. Univentsプラットフォームに関連したトレンド、利用状況、活動を分析すること。
6. Univentsと他者の権利、財産、または安全を保護するため、Univentsのポリシー順守を強化するため、ならびに適用法および政府の要請を順守するため、不正行為、悪用その他の違法な活動を検知、調査、防止すること。
7. ユーザーに関心がありそうな情報を提供するための分析、連絡をすること
8. Univentsが認めた大学等第三者への提供

2.2 ユーザーとのコミュニケーション

Univentsは、ユーザーとのコミュニケーションに関連する様々な目的のために、ユーザーについての情報を使用する場合があります。この目的には、下記のものが含まれます：

1. ユーザーのコメント、質問、リクエストに対応し、またカスタマーサービスを提供すること。
2. 弊社および他社の提供する製品、求人、サービス、イベントに関してユーザーとコミュニケーションをとること。
3. ユーザーがご自身のアカウントで示したコミュニケーションに関する設定に従って、その他のメンバーからのコミュニケーションをユーザーに通知すること。
4. 技術的な通知、アップデート、セキュリティ警告／サポート、また管理上のメッセージをユーザーに送信すること。

2.3 広告およびその他の用途

Univentsは、その他の様々な目的のために、ユーザーについての情報を使用する場合があります。この目的には、下記のものが含まれます：

1. メンバーのプロフィールまたは関心に一致するコンテンツ、特集、またはスポンサーシップを提供すること。
2. プロモーションを推進すること。
3. 本プライバシーポリシーに記載された目的のために、Univentsが収集する情報と組み合わせること。
4. Univentsが情報収集時にユーザーに説明したその他のあらゆる目的を実行すること。

2.4 データ処理に関する法的根拠

上述の情報の収集および使用に関する弊社の法的根拠は、情報の種類および弊社が情報を収集した特定の状況によって異なります。

1. 弊社は、利用規約に従ってサービスを提供するためにユーザーに関する情報を処理します。例えば、グループのフォローを許可するため、またはプロフィールを他のメンバーに表示するため、および弊社がユーザーに重要なサービスの更新を送信できるようにするためです。
2. 弊社は、情報を処理することが弊社の正当な利益であり、ユーザーの権利を侵害しない場合において、ユーザーに関する情報を処理します。（例えば、ダイレクトマーケティング、不正防止、ネットワークおよび情報システムのセキュリティ、ユーザーとのコミュニケーションへの対応、ネットワーク管理人によるグループネットワークの運営、およびプラットフォームの改善などが当てはまります。）
3. ユーザーは、ご自身に関する機密情報を弊社に提供する場合があります。例えば、特定の団体へのフォローという事実だけでも、健康、宗教、政治的見解、または性的アイデンティティに関する情報が示唆されることが考えられます。当該団体のフォローやプラットフォーム上でのこれらの関心トピックに関する情報の投稿は、完全に自発的なもので、ご自身の明示的な同意をもってなされるものです。
4. Univentsは、ユーザーに関する情報を収集する法的義務を有する場合があります。または、それ以外の場合にも、ご自身のおよび他のユーザーの重要な関心を保護するために、その情報が必要な場合もあります。
5. またUniventsは、法律要件を順守するため、または契約を履行するために情報を処理します。

## **3.情報の共有**

要約： Univentsは、本プライバシーポリシーに記載されている以外の方法では、ユーザーについての情報を共有しません。

Univentsは、ユーザーについての情報を開示する場合があります：

* ユーザーがプラットフォームを通じて行える選択に従い、オーガナイザーまたはソーシャルメディアサービスに対して。
* Univentsプラットフォームを弊社が運営または改善することを支援する、Univentsの第三者サービスプロバイダーに対して。
* 法的義務の順守、弊社の権利と財産の保護と防護のため、当局に対して。
* 企業間取引の一環として、関連会社または譲受事業体に対して。

Univentsはまた、識別不能処理がされた情報または集計された情報を共有する場合があります。

3.1 Univentsプラットフォームを介して

Univentsは、ユーザーのメンバープロフィールおよびアカウント設定に従ってプラットフォームに情報を表示することで、収集した情報の一部を共有します。

3.2 法律の順守およびUniventsの保護

Univentsは、ユーザーについての情報にアクセスし、それを保全し、またそれらの情報の第三者への開示が、適用される法律、規制、法的手続き、または監査に則った行為であるかそれらにより義務付けられる行為であると弊社が判断した場合には、その開示を行う場合があります。Univentsはまた、ユーザーの行動がUniventsの利用規約または関連するガイドラインおよびポリシーに反していると弊社が判断した場合、あるいはUniventsまたは他者の権利、または安全性を保護するためかUniventsまたは他者に対する詐欺または悪用を防止するために必要である場合には、ユーザーについての情報を開示する場合があります。

3.3 ソーシャルシェアなどのツール

本プラットフォームは、第三者サービスを使用して弊社のプラットフォームにおけるユーザーのアクションを共有する、ソーシャルシェア機能およびその他の統合ツールを提供することがあります（逆の場合もあります）。当該機能によって、ユーザーは本サービスで選択した設定に基づき、ユーザーの友人または一般の人々と情報を共有できます。

3.4 関連会社との共有、合併、売却などの資産移転

Univentsが合併、買収、資金調達、組織再編、破産、または資産売却に関係することとなった場合、ユーザーについての情報が、その取引の一環として共有、売却、または移転されることがあります。Univentsはまた、現在または将来の親会社、子会社、または関連会社と、ユーザーについての情報を共有する場合があります。

## **4.追加的な詳細事項**

要約：

* ユーザーの許可に基づき、Univentsは、分析サービスを提供するため、およびUniventsの広告を提供するため、ユーザーに関する情報を収集する技術を他社が使用することを許可する場合があります。ユーザーは、この情報の収集又は使用を停止することができます。
* 弊社は、ユーザーの個人情報を保護するために商業的に合理的な努力を行い、業界の最善慣行と一致する技術的および組織的対策を講じています。Univentsは、これらの管理で絶対的なセキュリティを提供することを保証できません。
* 本プライバシーポリシーは定期的に更新されますが、第三者には適用されません。

4.1 他社が提供する分析サービスと広告サービス

ユーザーの許可に基づき、弊社は、クッキー、ウェブビーコン、デバイス識別名、およびユーザーのプラットフォーム使用または他のウェブサイトやオンラインサービス使用に関する情報を収集するその他の技術の使用を他社に許可します。

4.2 本ポリシーの改定

Univentsは、本プライバシーポリシーを適時変更する場合があります。その際には、最新版を公開し、本ページの上部に更新日を表示して、ユーザーに通知します。本ポリシーへの重要な変更を行う場合は、弊社はユーザーにメールする、またはプラットフォームに目立つお知らせを掲示するなど追加的な通知を行います。

## **5.連絡先情報**

要約：

* ご質問がある場合は、privacy@Univents.jpまでご連絡ください。

ユーザーの情報の管理者はHarmony For Inc.です。

本プライバシーポリシーに関して、または弊社のユーザーの情報の使用方法に関して、ご質問や苦情がある場合は、privacy@Univents.jpにご連絡ください。
`
