<template>
  <modal @close="$emit('close')">
    <h3 slot="header">{{msg.popup_title}}</h3>
    <div slot="body">
      <v-form>
        <v-datetime-picker :label="msg.send_at" format="YYYY-MM-DD HH:mm" v-model="remind_event_mail.send_at"></v-datetime-picker>
        <v-textarea :label="msg.message" auto-grow v-model="remind_event_mail.custom_body"></v-textarea>
      </v-form>
      <v-btn color="success" @click="submit">{{msg.set}}</v-btn>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/shared/views/modal';
import axios from '@/store/axios'
import messages from '@/locales/remind.json'

export default {
  components: {
    Modal
  },
  props: [
    "remind_event_mail",
    "event"
  ],
  methods: {
    submit(){
      if (!this.remind_event_mail.id) {
        // idが存在しなかったらcreateにとばす
        axios.post(`/api/v1/events/${this.event.id}/remind_event_mails/create`, this.remind_event_mail.toPostedRemindEventMail()).then(resp=>{
          console.log(resp)
          this.event.update()
          this.$emit('close')
        })
      }else{
        // idが存在したらupdateへとばす
        axios.put(`/api/v1/events/${this.event.id}/remind_event_mails/${this.remind_event_mail.id}/update`,
           this.remind_event_mail.toPostedRemindEventMail()).then(resp=>{
          console.log(resp)
          this.event.update()
          this.$emit('close')
        })
      }
    }
  },
  computed: {
    msg() { return messages[this.$store.state.user.language] },
  }
  // data() {
  //   return {
  //     selected_lang_id: null
  //   }
  // },
  // computed: {
  //   organization_id(){ return this.event.owner_organization_id },
  //   event(){ return this.event }
  // },
  // methods: {
  //   submit() {
  //     console.log("提出はされたね")
  //     console.log(this.posted())
  //     axios.post(`/api/v1/language_events/create`, this.posted()).then(resp => {
  //       this.$emit('second-lang-created')
  //     })
  //   },
  //   posted() {
  //     return {
  //       event: this.event.id,
  //       language_id: this.selected_lang_id
  //     }
  //   }
  // }
}
</script>
