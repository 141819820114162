export default `
*Terms of Service Overview*

*The following is an overview of the Terms of Service for Univents' "Platform." "Platform" refers to any website, application or service offered by Univents.*

### Your relationship with Univents

* By using the Univents platform, you agree to the Univents Terms of Service, which form a legally binding contract between you and Univents.
* If you break the rules, Univents may suspend or terminate your account.

### Members of Univents

* Any member can also create their own group.
* The Univents platform allows users to meet people in the real world and engage in various activities in real life. However, this can sometimes lead to unexpected situations. Univents has no control over what happens in the real world and is not responsible for them. We ask users to use their own common sense and good judgment when interacting with others.

### User Content and Content of Others

* Users are responsible for their "Content" (meaning any information, materials or other content posted on the Univents Platform).
* Univents takes no responsibility for the Content posted or communications sent by Members using the Univents Platform. Generally, Univents does not review Content before it is posted. Users may report inappropriate Content to Univents if they see Content that violates the Univents Terms of Service.

### Univents Platform

* While Univents strives to keep the Univents Platform available and functional, we cannot guarantee that it will always be so. Occasionally, things may not go as planned. We apologize in advance for any inconvenience this may cause.
* Univents is committed to continuous improvement of the Univents Platform. To that end, Univents may modify or discontinue parts of the Univents Platform.
* By using the Univents Platform, you agree to the limitations and exclusions of liability set out in the Univents Terms of Service. You also agree that if you have a dispute with Univents, you will resolve it as set out in the Univents Terms of Service. You further agree to mandatory arbitration and waive any right to seek relief from a judge or jury in court, unless you are based in Japan. Claims may only be brought individually and not as part of a class action.
* Some users may be based in countries where different or additional rights and obligations apply. These Terms of Service address those regional variations.

Terms of Service

Last updated: 25/09/2024

Univents' Terms of Service contain important information about your legal rights. Please refer to the grey boxes for a summary and tips on each provision. Please note that these are not part of the official terms and conditions, so please read the wording of each provision carefully.

1\. This Agreement

Summary: You agree to follow some basic rules when using the Univents Platform. These rules are explained in these Terms of Service, including any related policies and guidelines explained below. Univents may change these rules from time to time.

1.1 Agreement. Univents is a service that helps users organize events. "Univents", "we", "us" includes Harmony For Inc., and "you" refers to any individual who uses the Univents Platform, and any organization or individual who uses the Univents Platform on behalf of an organization.

1.2 Amendments to this Agreement. Univents may amend this Agreement from time to time. If we do, we will notify you by publishing an updated version and changing the date at the top of this page. If Univents makes material changes to this Agreement, we will notify you by other means, such as by sending you an email or displaying a prominent notice on the Univents Platform. Your continued use of the Platform after the amendments become effective will constitute your acceptance of the amended Agreement. If you do not wish to accept the revised Agreement, you may close your Account.

2\. User Accounts and Membership

2.1 Modifying, Suspending, or Terminating User Accounts. Univents may modify, suspend, or terminate a User's account or a User's access to the Univents Platform. This may be done if the User violates this Agreement (including any policies or guidelines that are part of this Agreement) or if Univents sole discretion determines that doing so is in the best interest of the Univents community or necessary to protect the Univents brand or platform. The reason for modification, suspension, or termination shall be notified to the User. Univents may also delete Member accounts that have not been used for an extended period of time. If you believe that a modification, suspension, or termination has been made in error, please contact policy@Univents.jp.

Members who have the ability to exercise jurisdiction or control over certain parts of the Univents Platform also have the ability to modify, suspend, or terminate a User's access to those parts at their own discretion.

2.2 Account Information and Security. When you register, you provide Univents with basic information, including your email address and password. Please ensure that your account information, including your email address, is always up-to-date and accurate. You also agree to maintain the security and confidentiality of your password (otherwise Univents may be forced to disable your account). We strongly encourage you to use strong, unique words for your password and to practice good password practices to prevent unauthorized access to your account, including not using the same password for any other account or online service. You are solely responsible for any consequences resulting from your failure to maintain the security and confidentiality of your password, including by sharing your account credentials with others. If you believe that someone else is using your password or has accessed your account without your permission, please contact us at abuse@Univents.jp.

2.3 License to the Univents Platform and Services. Subject to your compliance with this Agreement, Univents grants you a limited, non-exclusive, revocable, non-sublicensable, non-transferable right to use the Platform to access and use the services and functionality made available to you by Univents.

3\. User Content and Privacy

In summary: You are responsible for the content you post on the Univents Platform or submit to Univents. You grant Univents permission to use such content to operate, improve, promote and protect Univents and the Univents Platform. Univents' Privacy Policy describes how Univents collects and uses information you provide about yourself and that may relate to you, and certain ways in which Univents may share that information.

3.1 User Content. You are responsible for the content you post on the Platform or provide to Univents. In this context, "content" refers to any information, materials or other content that you post or submit to Univents. Examples of User Content:

* Materials posted on the Platform by organisers and members, including information, comments and photos of Univents groups and Univents events.
* All feedback, tips and other messages you send to Univents.

You are responsible for your own Content and, in particular, you agree:

* You have all necessary permissions, rights and licenses to the Platform and Univents to provide the Content (including under copyright, trademark, contractual rights, privacy or publicity rights).
* Your Content does not contain any personal, confidential or proprietary information belonging to anyone else.
* Your Content does not violate the rights of any other person or entity.

3.2 Privacy. Univents collects information about users through the Univents Platform, including registration information. For more information about how Univents collects, uses and discloses this information, please see our Privacy Policy. These policies do not cover the use of information users provide to third parties, such as other members of the Univents Platform.

4\. Use of the Univents Platform

In summary: Univents expects users to comply with Univents policies and guidelines when using the Univents Platform. Univents is not responsible for content posted by third parties on the Univents Platform.

4.1 Our Policies, Guidelines, and Applicable Laws. Your use of our Platform is subject to the Usage and Content Policy, Group and Event Policy, Organizer and Leadership Standards, Member Restrictions Rules, Payment Policy, Trademark Usage Guidelines, and API License Terms. You agree to comply with all applicable laws, regulations, and ordinances, including without limitation these, and not to violate or infringe upon the rights of third parties.

Your access to and use of some areas or features of the Platform may also be subject to additional terms, policies, standards, or guidelines ("Additional Terms"). You must agree to the Additional Terms before accessing any such portion or feature of the Platform. If there is a conflict between the Terms of Use and the Additional Terms, the Additional Terms will take precedence with respect to your access to or use of any portion or feature of the Platform, unless otherwise specified in the Additional Terms.

When the Univents Platform uses third-party services to provide certain features or services to Members, Members may be required to comply with the terms of service applicable to those features or services. For example, some areas of the Univents Platform use mapping services provided by Google Maps and Google Earth. By using them on the Univents Platform, you agree to the Google Maps/Google Earth Additional Terms of Use.

Univents may, in its sole discretion, modify, suspend or terminate your account or access to the Platform if you fail to comply with the Terms. In such cases, Univents will provide you with the reasons for the modification, suspension or termination. If you believe that the modification, suspension or termination was made in error, please contact us at policy@Univents.jp.

4.2 Others' Content. Univents does not control other Members' Content. Univents reserves the right to investigate and take appropriate action if inappropriate content becomes known to it on the Univents Platform, but has no obligation or responsibility to monitor the content of other members.

4.3 Interactions with Others. Univents is not a party to offline arrangements made through the Univents Platform. Univents does not conduct or require identity verification of members, nor does it attempt to verify the truth or accuracy of any member's statements. Univents makes no representations or warranties regarding the conduct or content of any member or any interactions between members and users.

TIP: We ask that you use common sense and good judgment when using the Univents Platform to interact with other members, both on the platform and at Univents events. If you have any concerns about other members, please contact us at abuse@Univents.com.

4.4 Prohibited Activities on the Platform. The Univents Platform contains proprietary and confidential information and is protected by intellectual property and other laws. Unless expressly permitted by Univents through this Agreement, User agrees not to directly or indirectly: (a) use, host, store, copy, modify, publish, publicly display, publicly perform, distribute or create derivative works of the Platform or any portion thereof; (b) remove or alter any proprietary notices on the Platform; (c) reverse engineer, disassemble, decompile or attempt to discover the source code or structure, sequence or organization of the Platform; and (d) rent, lease, resell, distribute or use the Platform for any commercial purposes not contemplated by this Agreement. User also agrees not to use the Platform to collect: (i) personal data of others, except as necessary for the administration of or participation in a group or event, or (ii) sensitive personal data as provided for or handled by applicable law (including, by way of example only, health information, social security numbers and other national identification numbers). You also agree to comply with the export control laws of your local jurisdiction.

4.5 Safety and Security of the Platform. You agree not to directly or indirectly: (a) use any automated system or software, whether operated by a third party or otherwise ("screen scraping", "data scraping" or "web scraping"), to extract data from the Platform for commercial purposes not permitted by these Terms of Service; or (b) engage in any activity that interferes with or disrupts (or attempts to do so) or places an undue burden on the Platform or its systems.

You agree to use, retain and otherwise process personal data collected from the Platform in accordance with applicable laws, rules and regulations and solely for the purposes of administering and participating in the Univents Group and Univents events. Without limitation, User agrees to provide notice to individuals regarding the processing of their personal data, obtain any necessary consents, and respond to requests from individuals as required by applicable law. User also agrees to protect such data from unauthorized access or processing. User must promptly delete such personal data when it is no longer reasonably necessary for the administration of the Event or upon request by Univents, and shall promptly certify such deletion.

4.6 Changes to the Platform. Univents is committed to continuous improvement of the Univents Platform. Accordingly, Univents may modify or discontinue all or part of the Univents Platform with or without notice to, and without liability to, User or any third party.

4.7 Third Party Sites and Services. The Univents Platform contains links to third party sites and is integrated with various third party services, applications and sites that may provide User with content and products from outside vendors. Univents does not control these third parties and is not responsible for these sites, services, content, or products. Such third parties may have their own terms and policies, and your use of these sites, services, content, or products is subject to those terms and policies. Please note that merely accessing the Univents Platform does not grant you permission to use any third-party intellectual property.

5\. Dispute Resolution

In summary: If you have a dispute with Univents, you agree to first try to resolve it directly with us. If we are unable to reach a resolution, with limited exceptions, you must submit the dispute to arbitration by a neutral arbitrator rather than to a lawsuit in court. Requests may only be brought on an individual basis, not as a class action. Members who reside in Japan may have additional or different rights as provided by applicable law. Univents may assist in resolving disputes between you and other Members, but is under no obligation to become involved in such disputes.

6\. Intellectual Property

In summary: When using Univents trademarks, you must follow Univents' trademark usage guidelines and ensure that you do not infringe on the intellectual property of others.

6.1 Univents Intellectual Property. Univents trademarks, logos, service marks and service names are the intellectual property of Univents. The Univents Platform, including the materials on the Platform, are the intellectual property of Univents and its licensors. You agree not to use any of Univents' intellectual property without the prior written consent of Univents.

6.2 Intellectual Property of Others. Univents respects the intellectual property of others and expects Univents members to do the same. Univents may, in appropriate circumstances and in Univents' discretion, remove or disable access to materials that infringe the intellectual property rights of others. Univents may also limit or terminate access to the Univents Platform for users who Univents believes to be repeat infringers.

7\. General Provisions

Summary: This section contains additional terms that Univents has added for various purposes. Please continue to read this section carefully.

7.1 Translation. This Agreement was originally written in Japanese and has since been translated into other languages. In the event of any conflict between the Japanese version and any translated version, the Japanese version shall control.

7.2 Assignment. You may not assign, transfer or sublicense this Agreement without the prior written consent of Univents. Univents may assign or transfer this Agreement to any parent or any affiliate or subsidiary of Univents, or in connection with a transaction such as a merger, acquisition, corporate reorganization or sale of all or substantially all of Univents' assets.

7.3 No Waiver. The failure of either party to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of that right or provision or any right to act with respect to future or similar breaches.

7.4 Acknowledgements. Thank you for taking the time to read the Univents Terms of Service.

7.5 Termination. If Univents terminates your account or access to the Univents Platform, this Agreement shall terminate with respect to the terminated Member Account; provided, however, that the provisions set forth in Section 11.13 shall survive termination.

7.6 Survival. Sections 3.2 (Privacy), 5 (Dispute Resolution), and 7 (General Provisions) of this Agreement, as well as any other provisions necessary to give effect to these provisions, will survive any termination or expiration of this Agreement.

7.7 Violations. Please report any violations of this Agreement by Member or a third party by email to abuse@Univents.jp.


`
