import axios from '@/store/axios'

export default class Master {
  constructor() {
    this.init()
    axios.get('/api/v1/masters/all').then( resp => {
      this.languages = this.languages.concat( JSON.parse(resp.data.languages) )
      this.event_formats = this.event_formats.concat( JSON.parse(resp.data.event_formats) )
      this.event_purposes = this.event_purposes.concat( JSON.parse(resp.data.event_purposes) )
      this.universities = this.universities.concat( JSON.parse(resp.data.universities) )
      this.department_categories = this.department_categories.concat( JSON.parse(resp.data.department_categories) )
      this.countries = this.countries.concat( JSON.parse(resp.data.countries) )
      this.occupations = this.occupations.concat( JSON.parse(resp.data.occupations) )
      this.prefectures = this.prefectures.concat( JSON.parse(resp.data.prefectures) )
      this.question_types = this.question_types.concat( JSON.parse(resp.data.question_types) )

      this.department_en_capitalize_sort()

      this.is_fetched = true
    })
  }

  init() {
    this.languages = []
    this.event_formats = []
    this.event_purposes = []
    this.universities = []
    this.department_categories = []
    this.countries = []
    this.occupations = []
    this.prefectures = []
    this.question_types = []
    this.is_fetched = false
  }

  commonly_used_lanugages(){
    return this.languages.filter(function(lang){
      return lang.is_commonly_used
    })
  }
  not_commonly_used_lanugages(){
    return this.languages.filter(function(lang){
      return !lang.is_commonly_used
    })
  }

  language_find(id){
    return this.languages.filter(function(lang){
      return lang.id == id
    })[0]
  }

  occupation_find(id){
    return this.occupations.filter(function(oc){
      return oc.id == id
    })[0]
  }

  // sort and capitalize department names
  department_en_capitalize_sort(){
    // TODO: need to check if current locale is not jp

    // function to capitalize a word except for these words
    const cap = (str)=>["and","or","of","the"].includes(str) ? str : str[0].toUpperCase() + str.substring(1)

    // capitalize department names
    this.department_categories.forEach((item)=>{
      // replace 'word1/word2' to 'word1 / word2'
      item.name = item.name.replace(/\s?\/\s?/g," / ")

      // capitalize each word
      item.name = item.name.split(" ").map(word=>cap(word)).join(" ");
    })

    // sort department in alphabetical order
    this.department_categories.sort((a,b)=>a.name<b.name ? -1 : 1 )

  }
}
