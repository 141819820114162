import axios from '@/store/axios'
import display_lang from '@/models/display_lang';
import Event from '@/models/event'

export default class EventsCollection {
  constructor(arg) {
    this.init()
    if (typeof arg == "string") {
      axios.get(arg).then(resp => {
        resp.data.forEach(function (val) {
          var event = new Event(val)
          this.events.push(event)
        }.bind(this))
      })
    }
    if (typeof arg == "object") {
      arg.forEach(function (val) {
        var event = new Event(val)
        this.events.push(event)
      }.bind(this))
    }
  }

  init() {
    this.events = []
  }

  not_created_by_hf() {
    var events = this.events.filter(function (event) {
      return !event.is_created_by_hf
    })
    return new EventsCollection(events)
  }

  sort_by_date() {
    var events = this.events.sort(function (a, b) {
      return (a.start_at > b.start_at ? 1 : -1)
    });
    return new EventsCollection(events)
  }

  where(query) {
    var events = this.events.filter(function (event) {
      if (query.university_id && event.university_id != query.university_id) { return false }
      if (query.used_language_id && event.used_language_ids.indexOf(query.used_language_id) < 0) { return false }
      if (query.event_purpose_id && event.event_purpose_id != query.event_purpose_id) { return false }
      if (query.prefecture_id && event.target_prefecture_ids.length != 0 && event.target_prefecture_ids.indexOf(query.prefecture_id) < 0) { return false }
      return true
    })
    return new EventsCollection(events)
  }

}

