<template>
  <div id="app">

    <template v-if="is_manager">

      <v-toolbar color="grey">
        <v-toolbar-title class="white--text">Organizer Menu</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn class="mr-3" @click="is_show_edit_modal=true"> <v-icon>edit</v-icon> {{ msg.organizer_button_edit }} </v-btn>
        <v-btn @click="$router.push(`/manage/organizations/${event.owner_organization_id}/events/${event.id}`)"> {{ msg.organizer_button_manage_event }} </v-btn>
      </v-toolbar>

      <v-alert class="mt-4" prominent dense type="warning" relative v-if="event.notify_event_mails && event.notify_event_mails.length == 0">
        <v-row align="center">
          <v-col class="grow">{{ msg.organizer_notice_no_mail }}</v-col>
          <v-col class="shrink"><v-btn class="black--text" color="white" @click="$router.push(`/manage/organizations/${event.owner_organization_id}/events/${event.id}/announce`)">{{ msg.organizer_button_set_mail }}</v-btn></v-col>
        </v-row>
      </v-alert>

      <event-edit-modal v-if="is_show_edit_modal" :event="event" @close="is_show_edit_modal=false"></event-edit-modal>

    </template>

    <!-- 応募成功アラート -->
    <v-alert type="success" v-if="$route.query.just_applied">
      {{ msg.success_apply_notice }}
    </v-alert>

    <v-container fluid>
      <v-layout row wrap>
        <!-- ↓↓↓大きい時↓↓↓ -->
        <v-flex xs12 md8 class="d-none d-md-block">
          <v-card>
            <v-img class="mb-5 white--text" :src='event.thumbnail_url' alt="John">
              <v-card-title class="align-end fill-height">{{ event.title }}</v-card-title>
            </v-img>
          </v-card>
        </v-flex>
        <v-flex md4 class="d-none d-md-block">
          <v-card  style="position: fixed; right:30px;" width="300px">
            <v-card-title class="mb-4">{{event.title}}</v-card-title>
            <v-card-text>
              <div class="square text-center">
                <div class="square-content">
                  <span class="title font-weight-bold red--text">{{ format(event.start_at, "do") }}</span> <br>
                  <span class="title font-weight-bold">{{ format(event.start_at, "MMM") }}</span>
                </div>
              </div>
              <span class="ml-5 subtitle-1"> {{ format(event.start_at, "p") }} ~ {{ format(event.end_at, "p" ) }} </span>
            </v-card-text>
            <v-card-text>
              <v-icon class="mr-2">assistant_photo</v-icon> {{event.place_text()}}
            </v-card-text>
            <v-card-text>
              <v-icon class="mr-2">person</v-icon>{{event.organization_name()}}
            </v-card-text>
            <template v-if="event.is_appliable()">
              <v-card-actions>
                <v-btn block color="success" v-if="!is_appling" @click="didPushApply"><v-icon>check</v-icon> {{ apply_button_text }}</v-btn>
                <v-btn block v-if="is_appling" @click="didPushCancell">{{ msg.button_cancell }}</v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn block v-if="!is_appling && !is_set_remind" @click="didPushRemind"><v-icon>notifications_active</v-icon> {{ msg.button_remind_me }} </v-btn>
                <v-btn block v-if="is_set_remind && !is_appling" @click="didPushRemindCancell"><v-icon>notifications_off</v-icon> {{ msg.button_cancell_remind }} </v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </v-flex>
        <!-- ↑↑↑大きい時↑↑↑ -->
        <!-- ↓↓↓小さい時↓↓↓ -->
        <v-flex xs12 class="d-block d-md-none mb-4">
          <v-card>
            <v-card-title class="mb-4">{{event.title}}</v-card-title>
            <v-container>
              <v-row>
                <v-col cols=4>
                  <div class="square text-center">
                    <div class="square-content">
                      <span class="title font-weight-bold red--text">{{ format(event.start_at, "do") }}</span> <br>
                      <span class="title font-weight-bold">{{ format(event.start_at, "MMM") }}</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="8">
                  <p class="subtitle-1"> {{ format(event.start_at, "p") }} ~ {{ format(event.end_at, "p" ) }} </p>
                  <p><v-icon class="mr-2">assistant_photo</v-icon> {{event.place_text()}}</p>
                  <p><v-icon class="mr-2">person</v-icon>{{event.organization_name()}}</p>
                </v-col>
              </v-row>
            </v-container>
            <template v-if="event.is_appliable()">
              <v-card-actions>
                <v-btn block color="success" v-if="!is_appling" @click="didPushApply"><v-icon>check</v-icon> {{ apply_button_text }}</v-btn>
                <v-btn block v-if="is_appling" @click="didPushCancell">{{ msg.button_cancell }}</v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn block v-if="!is_appling && !is_set_remind" @click="didPushRemind"><v-icon>notifications_active</v-icon>{{ msg.button_remind_me }}</v-btn>
                <v-btn block v-if="is_set_remind && !is_appling" @click="didPushRemindCancell"><v-icon>notifications_off</v-icon>{{ msg.button_cancell_remind }}</v-btn>
              </v-card-actions>
            </template>


          </v-card>
        </v-flex>
        <v-flex xs12 md8 class="d-block d-md-none">
          <v-card>
            <v-img class="mb-5 white--text" :src='event.thumbnail_url' alt="John">
              <v-card-title class="align-end fill-height">{{ event.title }}</v-card-title>
            </v-img>
          </v-card>
        </v-flex>
        <!-- ↑↑↑小さい時↑↑↑ -->
      </v-layout>

      <v-layout wrap row>

        <v-flex xs12 md8>

          <v-tabs grow dark background-color="blue-grey darken-1" slider-color="blue-grey" class="elevation-2" active-class="blue-grey darken-3">
            <v-tab class="ml-0 font-weight-bold">{{event.main_language_event().language.name}}</v-tab>
            <v-tab class="font-weight-bold" v-if="event.second_language_event()">{{event.second_language_event().language.name}}</v-tab>
            <v-tab-item>
              <event-detail-tab-item :event="event" :lang_event="event.main_language_event()"></event-detail-tab-item>
            </v-tab-item>
            <v-tab-item>
              <event-detail-tab-item :event="event" :lang_event="event.second_language_event()"></event-detail-tab-item>
            </v-tab-item>
          </v-tabs>



        </v-flex>

      </v-layout>
    </v-container>




  </div>
</template>


<script type="text/javascript">
import Event from '@/models/event'
import  University from '@/models/university'
import axios from '@/store/axios'
import EventEditModal from '@/components/shared/views/event_edit_modal'
import EventDetailTabItem from '@/components/shared/views/event_detail_tab_item'
import messages from '@/locales/event_detail.json'
import open_another_tab from '@/lib/open_another_tab'

export default {
  props: [
    "event_id"
  ],
  components: {
    'event-edit-modal': EventEditModal,
    'event-detail-tab-item': EventDetailTabItem
  },
  data() {
    return {
      event: new Event(this.event_id),
      is_appling: false,
      is_manager: false,
      is_set_remind: false,
      is_show_edit_modal: false,
    }
  },
  methods: {
    didPushApply() {
      if (this.$store.state.user.authenticated) {
        this.segueToApply()
      }
      else {
        this.segueToApply()
      }
    },
    segueToApply(){
      if (this.event.is_created_by_hf){
        open_another_tab(this.event.detail_url)
      } else {
        this.$router.push(`/events/${this.event_id}/apply`)
      }
    },
    didPushRemind() {
      if (this.$store.state.user.authenticated) { // そのまま設定
        axios.post(`/api/v1/events/${this.event_id}/event_reminds/apply`).then(resp=>{
          this.is_set_remind = true
        })
      }
      else { // 暫定的にsignupページへ
        this.$router.push(`/signup`)
      }

    },
    didPushCancell() {
      axios.put(`/api/v1/events/${this.event_id}/applications/cancell`).then(resp=>{
        this.is_appling = false
      })
    },
    didPushRemindCancell() {
      axios.put(`/api/v1/events/${this.event_id}/event_reminds/cancell`).then(resp=>{
        this.is_set_remind = false
      })
    }
  },
  computed: {
    googleMapSrc() {
      var q
      if (this.event.main_language_event().place_name) { q = this.event.main_language_event().place_name }
      if (this.event.main_language_event().address) { q = this.event.main_language_event().address }
      if ( q ) { return `http://maps.google.co.jp/maps?&output=embed&q=${q}&z=18` }
    },
    msg(){
      return messages[this.$store.state.user.language]
    },
    apply_button_text(){
      if ( this.event.is_created_by_hf ) { return this.msg.button_to_detail }
      else { return this.msg.button_join }
    }
  },
  mounted(){
    if (!this.$store.state.user.authenticated) {
      return
    }
    axios
      .get(`/api/v1/events/${this.event_id}/applications/present`)
      .then(resp => {
        this.is_appling = resp.data.data
      })
    axios
      .get(`/api/v1/events/${this.event_id}/event_reminds/present`)
      .then(resp => {
        this.is_set_remind = resp.data.data
      })
    axios
      .get(`/api/v1/authentications/event/${this.event_id}`)
      .then(() => {
        this.is_manager = true
      })
      .catch(() => {})
  },
  watch: {
    "event.is_fetched": function(){
      var university = new University(this.event.target_universities[0])
      this.$store.commit("header_state/setUniversity", university)
    }
  }
}
</script>

<style media="screen">
  .square {
    width: calc(10vmin);
    height: calc(10vmin);
    border: solid;
    border-radius: calc(10vmin / 4);
    vertical-align: middle;
    position: relative;
    display: inline-block;
  }
  .square-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 80%;
    height: 4rem;
  }
  @media screen and (max-width: 960px){
    .square {
      width: calc(25vmin);
      height: calc(25vmin);
    }
  }
</style>
